<template>
	<div class="welcome">
		<div class="logo-welcome">
			<v-img
				class="logo-er-1"
				src="../assets/asset/logo-white@2x.png"
				lazy-src="../assets/asset/logo-white.png"
				max-width="176px"
				max-height="84px"
			>
			</v-img>
		</div>
		<v-container>
			<div justify="center">
				<div class="mb-3 text-center">
					<h1>Personalized Treatment For Beautiful You</h1>
				</div>
				<v-btn @click="$router.push('login')" block x-large class="mb-3">
					Masuk dengan nomor ponsel
				</v-btn>
				<v-btn
					block
					x-large
					white--text
					class="mb-3 btn-facebook"
					color="text-capitalize"
					@click.prevent="loginWithFacebook"
				>
					Masuk dengan Facebook
				</v-btn>
				<v-btn
					block
					x-large
					class="mb-3 text- red--text"
					@click.prevent="loginWithGoogle"
				>
					Masuk dengan Google</v-btn
				>
				<div class="text-underline" justify="center">
					<p class="text-center mb-4 mt-4">Tidak mempunyai akun?</p>
				</div>
				<v-btn
					block
					x-large
					class="btn-welcome mb-2 white--text"
					color="grey"
					@click="$router.push('register')"
				>
					Register akun baru
				</v-btn>
			</div>
		</v-container>
	</div>
</template>

<script>
	import { initFbsdk } from "../config/fb.js";
	import { LoginGoogle, LoginFB } from "../services/api";
	import helper from "../helpers/auth";
	//import router from "../router";
	export default {
		name: "welcome",
		mounted() {
			initFbsdk();
		},
		data: () => ({
			login_fb: {
				facebook_id: "",
				nama: "",
			},
			login_data: {
				email: "",
				nama: "",
			},
			user_data: {
				id: "",
				nama: "",
				email: "",
				token: "",
			},
			googleplus: null,
		}),
		methods: {
			loginWithGoogle() {
				if (
					/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
						navigator.userAgent
					)
				) {
					var googleplus = window.plugins.googleplus;
					googleplus.login(
						{},
						function(result) {
							var nama = result.displayName;
							var email = result.email;
							var login_data = {};
							var user_data = {};
							login_data["email"] = email;
							login_data["nama"] = nama;
							console.log(login_data);
							LoginGoogle(login_data).then((data) => {
								console.log(data);
								const token = data.data.Token;
								localStorage.setItem("user-token", token); // store the token in localstorage
								user_data.email = data.data.data.email;
								user_data.id = data.data.data.id;
								user_data.nama = data.data.data.nama;
								user_data.token = data.data.Token;
								console.log(user_data);
								helper.setData(user_data);
								//router.push("/");
								location.reload();
							});
						},
						function(error) {
							console.log(error);
						}
					);
				} else {
					this.$gAuth
						.signIn()
						.then((GoogleUser) => {
							// on success do something
							// console.log("GoogleUser", GoogleUser);
							// console.log("getId", GoogleUser.getId());
							// console.log("getBasicProfile", GoogleUser.getBasicProfile());
							// console.log("getAuthResponse", GoogleUser.getAuthResponse());
							var profile = GoogleUser.getBasicProfile();
							var nama = profile.getName();
							var email = profile.getEmail();
							var login_data = {};
							login_data["email"] = email;
							login_data["nama"] = nama;
							console.log(login_data);
							LoginGoogle(login_data).then((data) => {
								console.log(data);
								const token = data.data.Token;
								localStorage.setItem("user-token", token); // store the token in localstorage
								this.user_data.email = data.data.data.email;
								this.user_data.id = data.data.data.id;
								this.user_data.nama = data.data.data.nama;
								this.user_data.token = data.data.Token;
								console.log(this.user_data);
								helper.setData(this.user_data);
								//router.push("/");
								location.reload();
							});
						})
						.catch((error) => {
							console.log("error", error);
						});
				}
			},
			loginWithFacebook() {
				window.FB.login((response) => {
					console.log(response);
					if (response.authResponse) {
						window.FB.api("/me", function(response) {
							var id = response.id;
							var nama = response.name;
							var login_fb = {};
							login_fb["facebook_id"] = id;
							login_fb["nama"] = nama;
							//console.log(login_fb);
							LoginFB(login_fb).then((res) => {
								// console.log(res);
								var token = res.data.Token;
								var id = res.data.data.id;
								var nama = res.data.data.nama;
								var user_data = {};
								localStorage.setItem("user-token", token);
								user_data["id"] = id;
								user_data["nama"] = nama;
								user_data["token"] = token;
								//console.log(token);
								helper.setData(user_data);
								//router.push("/");
								location.reload();
							});
						});
					}
				}, this.params);
			},
		},
	};
</script>

<style>
	@import "../assets/css/welcome.component.scss";
</style>
